@import "_variables";

// Custom.scss
// Option B: Include parts of Bootstrap

// variable overrides
$enable-responsive-font-sizes: true;
$primary: $brand;
$secondary: $secondary;
$dark: $dark;
$success: #25c87c;
$body-bg: $black;
$body-color: $light;
$tooltip-bg: $secondary;
$font-weight-base: 300;
$font-family-sans-serif: $primary-font;
$headings-font-family: $secondary-font;
$headings-font-weight: 400;
$headings-margin-bottom: 1rem;
$input-btn-focus-color: fade-out($brand, 0.75);
$input-focus-border-color: $brand;
$border-color: $border;
$input-border-color: $border;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-xl: 0;
$border-radius-2xl: 0;
// $btn-font-size-lg: 1rem;
$dropdown-dark-bg: $card-bg;

// cards
$card-bg: $card-bg;
$list-group-border-color: $border;
$list-group-bg: $card-bg;
$card-cap-bg: lighten($card-bg, 5%);
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;

// pagination variable overrides
$pagination-color: fade-out($black, 0.25);
$pagination-hover-bg: fade-out($brand, 0.75);
$pagination-hover-border-color: fade-out($brand, 0.5);
$pagination-active-color: $white;
$pagination-active-bg: $brand;
$pagination-active-border-color: $brand;

// alert variable overrides
$alert-margin-bottom: 0;
$alert-border-radius: 0;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";
