@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Nav Toggler
// ============================

footer {
  clear: both;
  color: darken($white, 30%);
}

.footer-contact {
  font-size: .9rem;

  a {
    color: darken($white, 5%);
    text-decoration: none;
  }
}

.footer-disclaimer p {
  font-size: .7rem;
  line-height: 1;
  font-style: italic;
}

.sosumi {
  font-size: .8rem;
  color: darken($white, 50%);

  a {
    color: darken($white, 30%);
    text-decoration: none;
  }
}
