@import "_variables";
@import "_placeholders";
@import "_mixins";

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

// ============================
// Skip to Main Content
// ============================
:root {
  --bs-link-color: white;
  --bs-link-hover-color: white;
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus,
a.skip-main:active {
  color: $white;
  background-color: darken($brand, 5%);
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

main {
  flex-grow: 1;
}

.breadcrumb {
  background-color: none;
}

.bg-breadcrumb {
  background-color: #e9ecef;
}

// ============================
// Utilities
// ============================

// prevent content from being too stretched on wide screens
.w-max,
.container-fluid {
  max-width: 2000px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

// backgrounds
.bg-primary {
  &--dark {
    background: darken($brand, 12%) !important;
  }

  &--trans {
    background: fade-out($brand, 0.75) !important;
  }
}

.bg-black {
  background: $black !important;

  &--trans {
    background: fade-out($black, 0.75) !important;
  }
}

.bg-gray {
  background: darken($dark-grey, 8%) !important;
}

.trans {
  &--5 {
    opacity: 0.5 !important;
  }
}

.font-1 {
  font-family: $primary-font !important;
}

.font-2 {
  font-family: $secondary-font !important;
}

// ============================
// Typography
// ============================

::selection {
  background: $dark;
  color: $white;
}

h1,
.fs-1 {
  letter-spacing: 2px;
  // text-transform: uppercase;
  @include mediaQuery($min, $lg) {
    font-size: 3vw;
  }
}

h5,
.h5 {
  font-family: $primary-font;
  font-weight:600;
  font-size: 0.9rem;
  line-height: 1.1;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// p,
// .block-richtext ul,
// .block-richtext ol {
//   font-size: 1.2rem;
//   line-height: 1.7;
// }

strong,
b,
.fw-bold {
  font-weight: 600;
}

// ============================
// Buttons
// ============================

.btn {
  text-transform: uppercase;
  letter-spacing: 1px;
  // font-family: $secondary-font;
  @include hover {
    transition: background 0.25s ease-in-out;
  }
}

button:focus,
a:focus,
li:focus {
  outline-color: fade-out($brand, 0.75);
}

// ============================
// Forms
// ============================

.small-form {
  max-width: 600px;
  margin: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.picker__input.picker__input--active {
  border-color: $brand;
}

#id_event-date {
  background: $white;
}

label,
.col-form-label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9rem;
}

.form-control {
  font-size: 16px;
}

// ============================
// Pagination
// ============================

.pagination,
.breadcrumb {
  font-size: 0.8rem;
}

// ============================
// Share Actions
// ============================

.actions {
  .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
}

.action {
  width: 30px;
  height: 30px;
  font-size: 1.1rem;

  @include mediaQuery($max, $md) {
    width: 25px;
    height: 25px;
    font-size: 0.9rem;
  }

  &:hover {
    text-decoration: none;
  }
}

// ============================
// List Items Module
// ============================

.list {
  @extend %flex-stretch;
  flex-wrap: wrap;

  li {
    width: 100%;
  }

  &--row {
    @include mediaQuery($min, $xl) {
      li {
        width: 50%;
      }
    }
  }
}

.item {
  @extend %flex-stretch;
  background: darken($white, 5%);
  color: darken($dark-grey, 15%);
  border-radius: 0.25rem;
  position: relative;
  height: 100%;
  width: 100%;

  & > div:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  & > div:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  @include mediaQuery($max, $md) {
    flex-wrap: wrap;

    & > div:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }

    & > div:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  &:hover {
    text-decoration: none;
    color: $dark-grey;
  }

  @include hover-none {
    h3 {
      color: darken($brand, 5%);
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    background: darken($white, 5%);

    @include mediaQuery($max, $md) {
      width: 100%;
      height: 200px;
    }

    img {
      @extend %absolute-img;
      opacity: 1;

      @include hover {
        transition: opacity 0.2s;
      }

      .item:hover & {
        opacity: 0.85;
      }

      @include mediaQuery($max, $md) {
        max-height: 200px;
      }
    }
  }

  &__btn {
    @extend %flex-center;
    @include font-size(2rem, 2rem);
    background: transparent;
    color: $brand;
    padding: 1rem;

    @include hover {
      transition: background 0.2s, color 0.2s;
    }

    @include mediaQuery($max, $md) {
      padding: 0.5rem;
    }

    .item:hover & {
      background: $brand;
      color: $white;
    }
  }

  &__content {
    flex: 1;
    padding: 1rem 1.2rem;

    @include hover {
      transition: background 0.2s;
    }

    @include mediaQuery($max, $md) {
      padding: 0.75rem;
    }

    @include mediaQuery($max, $sm) {
      padding: 0.5rem;
    }

    .card-title {
      .item:hover & {
        color: $brand;
      }
    }

    p {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(10px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .block-richtext {
    padding: 0;

    h3 {
      font-size: 1.5rem;
    }
  }
}

@media (min-width: 720px) {
  .card-grid {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
}

@media (min-width: 960px) {
  .card-grid {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }
}

:root {
  --scrollbar-foreground: rgba(255, 255, 255, .6);
  scrollbar-color: var(--scrollbar-foreground) rgba(0,0,0,0);
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
  }
}

.skinny-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-foreground) rgba(0,0,0,0);
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
  }
}

.with-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.with-sidebar > :first-child {
  flex-grow: 1;
}

.with-sidebar > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
}

.stack > * + * {
  margin-top: 1rem;
}

.stack-sm > * + * {
  margin-top: .5rem;
}

.cluster {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.form-check .form-check-input {
  border-color: #404040;
}
