@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Navbar
// ============================

.navbar-brand {
  background: $black;
}

.nav-link.dropdown-toggle:after {
  display: none;
}

.nav-link {
  font-family: $secondary-font;
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255,255,255,.3);
  padding: 1rem 2rem;
}

// ============================
// Hamburger
// ============================

.hamburger {
  background: $black;
}

.hamburger__line {
  background: $brand;
  height: 1px;
  width: 40px;
  transition: transform 0.35s, opacity 0.35s;

  &--2 {
    margin: 0.5rem 0;

    .hamburger--clicked & {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  &--1 {
    .hamburger--clicked & {
      transform: translateY(300%) rotate(225deg);
    }
  }

  &--3 {
    .hamburger--clicked & {
      transform: translateY(-300%) rotate(-225deg);
    }
  }
}

// ============================
// Nav Toggler
// ============================

.navbar-toggler {
  color: $brand;
  border: none;
  z-index: 10002;
  font-size: 1.5rem;

  .fa-times {
    display: none;
  }

  &[aria-expanded="true"] {

    .fa-times {
      display: inline;
    }

    .fa-bars {
      display: none;
    }
  }
}
