// Fonts
$primary-font: "IBM Plex Sans", sans-serif;
$secondary-font: "Alumni Sans Pinstripe", sans-serif;

// Colors
$white: #fff;
$black: #091225;
$black: desaturate(#091225, 12%);
$dark-grey: #5a5a5a;
$brand: #0d6efd;
$secondary: #013c93;
$dark: lighten($black, 15%);
$light: #ecf0f6;
$border: darken(white, 75%);
$card-bg: lighten($black, 5%);
