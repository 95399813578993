@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Login
// ============================

.login__wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10005;
}

.login__content {
  position: relative;
  z-index: 2;
  width: 500px;
  max-width: 100%;

  // @include mediaQuery($min, $lg) {
  //   margin-top: 200px;
  // }
}
